
import { Options, Vue } from 'vue-class-component';

@Options({
	props: {
		page:{
			type:String
		},
		name:{
			type:String
		},
		icon:{
			type:String
		},
	},
})
export default class Home extends Vue {
	//
}
