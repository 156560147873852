
import { Options, Vue } from 'vue-class-component';

import PageMenuItem from "@/components/page-menu-item/index.vue"


@Options({
	components: {
		PageMenuItem
	},
})
export default class Home extends Vue {
	private list=[
		{name:"检查宝石",page:"/gem/check",icon:"/images/gem.png"},
		{name:"语言文件工具",page:"/lang/editer",icon:"/images/lang.svg"},
	];
}
